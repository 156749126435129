<template>
  <v-container fluid>
    <v-card elevation="6" outlined>
      <v-card-text class="ma-0 pa-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.INGRREDIENT"
            :datatable-headers="datatableHeaders"
            :excel-columns="excelColumns"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @editEntry="editEntry"
            excel-file-name="Zutaten"
            ref="ingredients"
            show-delete-buttons
            show-edit-buttons
            :searchPayload="searchPayload"
            :datatable-attach-search="datatableAttachSearch"
            :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
            :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
        >
          <template v-slot:item.net="{item}">
            {{item.net | currency}}
          </template>

        </Datatable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

//configs
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
//components
import Datatable from "../../../datatable/Datatable";
import Template from "../../../settings/customerdisplay/Template";


export default {
  name: "IngredientComponet",
  components: {
    Template,
    Datatable
  },
  data() {
    return {
      ENDPOINTS,
      ingredientGroups:[],
      datatableAttachSearch:[{text: this.$t('erp.lang_ingredientGroups'), value: 'ingredientGroups', searchType: 'multiselect', searchCol: 6}]
    }
  },
  methods: {
    getIngredientGroups(){
      this.axios.post(ENDPOINTS.ERP.INGRREDIENTGROUP.GETALL).then((res) => {
        if (res.data.STATUS === "SUCCESS")
          this.ingredientGroups = res.data.groups;
      });
    },
    editEntry(entry) {
      this.$router.push('/erp/baseData/ingredients/edit/' + entry.id)
    },
    delete(idsToDelete) {
      this.$swal({
        title: this.$t('erp.lang_deleteIngredient'),
        text: this.$t('erp.lang_deleteIngredientBody'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.INGRREDIENT.DELETE, {
            deleteItemID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_deleteIngredientSuccess'),
                color: "success"
              });

              this.$refs.ingredients.getDataFromApi();
              this.$refs.ingredients.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
              });
            }
          }).catch((err) => {

            Events.$emit("showSnackbar", {
              color: "error",
              message: err.message
            });
          })
        }
      });
    },
    deleteEntry(entry) {
      this.delete([entry.id])
    },
    deleteEntries(ids) {
      this.delete(ids);
    },
  },
  computed: {
    searchPayload() {
      let data = {};

      data["ingredientGroups"] = this.ingredientGroups.map((ingredientGroup) => {
        return {
          id: ingredientGroup.uuid,
          name: ingredientGroup.name
        }
      }).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      return data;
    },
    datatableHeaders: function () {
      return [
        {
          text: this.$t("erp.lang_id"),
          value: "id",
          hide: true
        },
        {text: this.$t("erp.lang_ware_create_id"), value: "num"},
        {
          text: this.$t("erp.lang_posItemName"),
          value: "name",
          sort: "asc"
        },
        {text: this.$t("erp.internal_wareNo"), value: "wareNo"},
        {text: this.$t("erp.lang_ek_net_short"), value: "net"},
        {text:  this.$t('erp.lang_ingredientGroups'), value: "ingredientGroupName"},
        {text: 'Ingredient group uuid', value: "ingredientGroupUUID",hide:true},
      ];
    },
    excelColumns: function () {
      return [
        {
          label: this.$t("erp.lang_id"),
          field: "id",
        },
        {label: this.$t("erp.lang_ware_create_id"), field: "num"},
        {
          label: this.$t("erp.lang_posItemName"),
          field: "name"
        },
        {label: this.$t("erp.internal_wareNo"), field: "wareNo"},
        {label: this.$t("erp.lang_ek_net_short"), field: "net"},
      ];
    }
  },
  mounted() {
    this.getIngredientGroups()
  }
}
</script>

<style scoped>

</style>
